import CreditSimulator from "@components/Simulator/CreditSimulator";
import CreditSimulator2 from "@components/Simulator/CreditSimulator2";
import { LayoutBudget } from "@components/Structural/Budgets/Layout/Layout";
import React, { useState } from "react";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { useAuth } from "@hooks";

const CreditSimulatorView = () => {
  useAuth({ redirectIfNotLogged: true });

  const [page, setPage] = useState(1);
  const [monthPayment, setMonthPayment] = useState(0);
  const [term, setTerm] = useState(10);
  const [rate] = useState(0.03);

  return (
    <LayoutBudget
      title="Simulador básico de crédito"
      onTurnBackClick={() => navigate(Route.rokipedia + "/?#tools")}
    >
      {page == 1 ? (
        <CreditSimulator
          setPage={setPage}
          setMonthPayment={setMonthPayment}
          term={term}
          setTerm={setTerm}
          rate={rate}
        />
      ) : (
        <CreditSimulator2
          setPage={setPage}
          monthPayment={monthPayment}
          term={term}
          rate={rate}
        />
      )}
    </LayoutBudget>
  );
};

export default CreditSimulatorView;
